/* desktop nav color changes */
.cfa-navlink.navlink-sidenavigation-color-primary.active {
  color: #004F71;
}

.cfa-navlink.navlink-sidenavigation-color-primary.active>.sidenav-border-right {
  background-color: #004F71;
}

.cfa-navlink.navlink-sidenavigation-color-primary:hover,
.cfa-navlink.navlink-sidenavigation-color-primary:focus,
.cfa-navlink.navlink-sidenavigation-color-primary:active
 {
  background-color: rgb(0, 79, 113, .06);
  outline: none;
}

.cfa-side-navigation-wrapper .cfa-side-navigation-page-content {
  padding: 0;
}

.cfa-side-navigation-wrapper > nav {
  z-index: 5;
}
/* end desktop nav color changes */


/* mobile nav drawer color changes */
.cfa-navlink.navlink-drawer-color-primary.active, .cfa-navlink.navlink-drawer-color-primary:hover {
  color: #004F71;
}

.cfa-navlink.navlink-drawer-color-primary.active:after {
  border-color: #004F71;
}

.cfa-top-navigation {
  z-index: 1251;
}
.cfa-navlink.navlink-drawer-color-primary.active .sidenav-border-left, .cfa-navlink.navlink-drawer-color-primary.active>.sidenav-border-right, .cfa-navlink.navlink-drawer-color-tertiary.active .sidenav-border-left, .cfa-navlink.navlink-drawer-color-tertiary.active>.sidenav-border-right {
  background-color: #004F71;
}
/* end mobile nav drawer color changes */

/* input border color overrides */
input.cfa-textfield, button.cfa-dropdown, .cfa-radiobutton, .cfa-checkbox {
  border-color: #CCCFD0;
}

/* accordian changes */
.cfa-accordion-item-header-content {
  line-height: 24px;
}
.cfa-accordion-item-header:focus{
  background-color: white;
}
/* end accordian changes */

/* fixes white gradient on scrolling tabs */
.cfa-left-overlay:before, .cfa-right-overlay:after {
  height: 100%;
}

/* guaruntee dropdown on top */
.cfa-base-popper-wrap{
  z-index: 1252 !important;
}
/* TEMPORARY loader css override */
.cfa-loading-dot-page-i0 {
  transform: rotate(calc(0 * calc(360deg / 60)));
  animation-delay: calc(0 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i1 {
  transform: rotate(calc(1 * calc(360deg / 60)));
  animation-delay: calc(1 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i2 {
  transform: rotate(calc(2 * calc(360deg / 60)));
  animation-delay: calc(2 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i3 {
  transform: rotate(calc(3 * calc(360deg / 60)));
  animation-delay: calc(3 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i4 {
  transform: rotate(calc(4 * calc(360deg / 60)));
  animation-delay: calc(4 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i5 {
  transform: rotate(calc(5 * calc(360deg / 60)));
  animation-delay: calc(5 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i6 {
  transform: rotate(calc(6 * calc(360deg / 60)));
  animation-delay: calc(6 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i7 {
  transform: rotate(calc(7 * calc(360deg / 60)));
  animation-delay: calc(7 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i8 {
  transform: rotate(calc(8 * calc(360deg / 60)));
  animation-delay: calc(8 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i9 {
  transform: rotate(calc(9 * calc(360deg / 60)));
  animation-delay: calc(9 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i10 {
  transform: rotate(calc(10 * calc(360deg / 60)));
  animation-delay: calc(10 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i11 {
  transform: rotate(calc(11 * calc(360deg / 60)));
  animation-delay: calc(11 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i12 {
  transform: rotate(calc(12 * calc(360deg / 60)));
  animation-delay: calc(12 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i13 {
  transform: rotate(calc(13 * calc(360deg / 60)));
  animation-delay: calc(13 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i14 {
  transform: rotate(calc(14 * calc(360deg / 60)));
  animation-delay: calc(14 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i15 {
  transform: rotate(calc(15 * calc(360deg / 60)));
  animation-delay: calc(15 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i16 {
  transform: rotate(calc(16 * calc(360deg / 60)));
  animation-delay: calc(16 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i17 {
  transform: rotate(calc(17 * calc(360deg / 60)));
  animation-delay: calc(17 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i18 {
  transform: rotate(calc(18 * calc(360deg / 60)));
  animation-delay: calc(18 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i19 {
  transform: rotate(calc(19 * calc(360deg / 60)));
  animation-delay: calc(19 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i20 {
  transform: rotate(calc(20 * calc(360deg / 60)));
  animation-delay: calc(20 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i21 {
  transform: rotate(calc(21 * calc(360deg / 60)));
  animation-delay: calc(21 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i22 {
  transform: rotate(calc(22 * calc(360deg / 60)));
  animation-delay: calc(22 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i23 {
  transform: rotate(calc(23 * calc(360deg / 60)));
  animation-delay: calc(23 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i24 {
  transform: rotate(calc(24 * calc(360deg / 60)));
  animation-delay: calc(24 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i25 {
  transform: rotate(calc(25 * calc(360deg / 60)));
  animation-delay: calc(25 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i26 {
  transform: rotate(calc(26 * calc(360deg / 60)));
  animation-delay: calc(26 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i27 {
  transform: rotate(calc(27 * calc(360deg / 60)));
  animation-delay: calc(27 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i28 {
  transform: rotate(calc(28 * calc(360deg / 60)));
  animation-delay: calc(28 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i29 {
  transform: rotate(calc(29 * calc(360deg / 60)));
  animation-delay: calc(29 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i30 {
  transform: rotate(calc(30 * calc(360deg / 60)));
  animation-delay: calc(30 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i31 {
  transform: rotate(calc(31 * calc(360deg / 60)));
  animation-delay: calc(31 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i32 {
  transform: rotate(calc(32 * calc(360deg / 60)));
  animation-delay: calc(32 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i33 {
  transform: rotate(calc(33 * calc(360deg / 60)));
  animation-delay: calc(33 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i34 {
  transform: rotate(calc(34 * calc(360deg / 60)));
  animation-delay: calc(34 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i35 {
  transform: rotate(calc(35 * calc(360deg / 60)));
  animation-delay: calc(35 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i36 {
  transform: rotate(calc(36 * calc(360deg / 60)));
  animation-delay: calc(36 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i37 {
  transform: rotate(calc(37 * calc(360deg / 60)));
  animation-delay: calc(37 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i38 {
  transform: rotate(calc(38 * calc(360deg / 60)));
  animation-delay: calc(38 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i39 {
  transform: rotate(calc(39 * calc(360deg / 60)));
  animation-delay: calc(39 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i40 {
  transform: rotate(calc(40 * calc(360deg / 60)));
  animation-delay: calc(40 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i41 {
  transform: rotate(calc(41 * calc(360deg / 60)));
  animation-delay: calc(41 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i42 {
  transform: rotate(calc(42 * calc(360deg / 60)));
  animation-delay: calc(42 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i43 {
  transform: rotate(calc(43 * calc(360deg / 60)));
  animation-delay: calc(43 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i44 {
  transform: rotate(calc(44 * calc(360deg / 60)));
  animation-delay: calc(44 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i45 {
  transform: rotate(calc(45 * calc(360deg / 60)));
  animation-delay: calc(45 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i46 {
  transform: rotate(calc(46 * calc(360deg / 60)));
  animation-delay: calc(46 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i47 {
  transform: rotate(calc(47 * calc(360deg / 60)));
  animation-delay: calc(47 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i48 {
  transform: rotate(calc(48 * calc(360deg / 60)));
  animation-delay: calc(48 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i49 {
  transform: rotate(calc(49 * calc(360deg / 60)));
  animation-delay: calc(49 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i50 {
  transform: rotate(calc(50 * calc(360deg / 60)));
  animation-delay: calc(50 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i51 {
  transform: rotate(calc(51 * calc(360deg / 60)));
  animation-delay: calc(51 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i52 {
  transform: rotate(calc(52 * calc(360deg / 60)));
  animation-delay: calc(52 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i53 {
  transform: rotate(calc(53 * calc(360deg / 60)));
  animation-delay: calc(53 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i54 {
  transform: rotate(calc(54 * calc(360deg / 60)));
  animation-delay: calc(54 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i55 {
  transform: rotate(calc(55 * calc(360deg / 60)));
  animation-delay: calc(55 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i56 {
  transform: rotate(calc(56 * calc(360deg / 60)));
  animation-delay: calc(56 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i57 {
  transform: rotate(calc(57 * calc(360deg / 60)));
  animation-delay: calc(57 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i58 {
  transform: rotate(calc(58 * calc(360deg / 60)));
  animation-delay: calc(58 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i59 {
  transform: rotate(calc(59 * calc(360deg / 60)));
  animation-delay: calc(59 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i60 {
  transform: rotate(calc(60 * calc(360deg / 60)));
  animation-delay: calc(60 * 2s/60 * 1 - 1.5s);
}

.cfa-loading-dot-page-i61 {
  display: none;
}
.cfa-loading-dot-page-i62 {
  display: none;

}
.cfa-loading-dot-page-i63 {
  display: none;
}
/* end temporary loader override */

/* modal renders over navbar */
.cfa-modal, .cfa-modal-overlay {
  z-index: 1251;
}
/* end modal renders over navbar */

.cfa-progress-bar-wrapper {
  width: 100%;
}

.cfa-card, .cfa-accordion-item {
  border: 1px solid #cccfd0 !important;
  border-radius: 4px;
}

.cfa-modal-body-scroll .cfa-modal-body:focus {
  outline: none;
}

.cfa-modal-body-scroll .cfa-modal-content {
  height: 100%;
}

/* stops checkbox from compressing in some layouts */
.cfa-checkbox {
  min-width: 24px;
}


/* stops toast from looking horrible on mobile with long title */
.cfa-message-block .cfa-content-col .cfa-message-block-title {
  padding: 12px 0;
}